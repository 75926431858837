<template>
	<div class="h-screen py-[80px] px-0 sm:px-[80px] mx-auto max-w-[1440px]">
		<div class="border h-full border-mine-shaft px-[60px] sm:px-[76px]">
			<div
				class="flex h-full flex-col justify-between mx-auto rounded-none border-l border-l-mine-shaft border-r border-r-mine-shaft sm:max-w-[488px]"
			>
				<div class="flex h-[99px]">
					<div class="flex-1 border-b border-b-mine-shaft" />
					<div class="flex-1 border-b border-b-mine-shaft border-l border-l-mine-shaft" />
				</div>
				<div class="flex-grow px-5 sm:px-[72px]">
					<div class="w-full h-full flex flex-col justify-between py-[60px] gap-y-[20px]">
						<img src="@/assets/images/anydoc.svg" width="344" class="mx-auto" />

						<el-form :model="formData" ref="form" @keyup.enter="login">
							<el-form-item prop="login" class="mb-5" :rules="requiredRule">
								<el-input
									v-model="formData.login"
									placeholder="Имя пользователя"
									class="w-full"
									size="size-48"
								/>
							</el-form-item>
							<el-form-item prop="password" :rules="requiredRule">
								<el-input
									v-model="formData.password"
									placeholder="Пароль"
									class="w-full"
									size="size-48"
									show-password
								/>
							</el-form-item>
						</el-form>

						<el-button :loading="isSending" @click="login">Войти</el-button>

						<div>
							<img
								src="@/assets/images/Indital.svg"
								width="98"
								class="mx-auto mb-2"
							/>
							<div class="text-nero text-center">2024 @ LLC "INDITAL CUSTOMS"</div>
						</div>
					</div>
				</div>
				<div class="flex h-[99px]">
					<div class="flex-1 border-t border-t-mine-shaft" />
					<div
						class="flex-1 rounded-none border-l border-l-mine-shaft border-t border-t-mine-shaft"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import injectStrict from '@/utils/provide-inject/inject-strict'
import { notifyErrorInjectionKey } from '@/utils/provide-inject/injection-keys'
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { requiredRule, validateForm } from '@/utils/validation'

const $notifyUserAboutError = injectStrict(notifyErrorInjectionKey)

const store = useStore()

const form = ref()
const formData = reactive({
	login: '',
	password: '',
})
const isSending = ref(false)

async function login() {
	if (!(await validateForm(form))) return

	isSending.value = true

	try {
		await store.dispatch('auth/login', formData)
		await store.dispatch('user/setInitials')
	} catch (error) {
		$notifyUserAboutError(
			error.response.status === 401
				? 'Проверьте правильность введенных логина и/или пароля'
				: error
		)
	} finally {
		isSending.value = false
	}
}
</script>
