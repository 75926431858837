import { Ref } from 'vue'

export const requiredRule = {
	required: true,
	message: 'Обязательное поле',
	trigger: ['blur', 'change'],
}

export async function validateForm(form: Ref): Promise<boolean> {
	let isValid = false

	await form.value.validate((valid: boolean) => (isValid = valid))

	return isValid
}
